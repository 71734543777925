import { render, staticRenderFns } from "./comp-info.vue?vue&type=template&id=28f4de68&scoped=true&lang=pug&"
import script from "./comp-info.vue?vue&type=script&lang=js&"
export * from "./comp-info.vue?vue&type=script&lang=js&"
import style0 from "./comp-info.vue?vue&type=style&index=0&id=28f4de68&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28f4de68",
  null
  
)

/* custom blocks */
import block0 from "./comp-info.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports