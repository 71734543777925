<!--
 * @Description: 项目简介组件
 * @Date: 2022-01-25 16:05:22
 * @LastEditTime: 2022-02-21 15:23:21
-->
<template lang="pug">
  div.info-container
    p.info-message {{ infoMessage }}
    p.contact-us(v-html="contactUs")
</template>

<script>
const zhInfo = '本页面为荟花官方直播间您可以与主播及其他观众聊天互动。';
const enInfo = '本页面为荟花官方直播间您可以与主播及其他观众聊天互动。';
const zhContactUs = '请您在使用本直播间时注意言行举止，不要发送任何违规内容，避免被系统标记。';
const enContactUs = '请您在使用本直播间时注意言行举止，不要发送任何违规内容，避免被系统标记。';
export default {
  name: 'video-record',
  data() {
    return {
      infoMessage: '',
      contactUs: '',
    };
  },
  watch: {
    '$i18n.locale': {
      immediate: true,
      handler(val) {
        if (val === 'zh') {
          this.infoMessage = zhInfo;
          this.contactUs = zhContactUs;
        } else if (val === 'en') {
          this.infoMessage = enInfo;
          this.contactUs = enContactUs;
        }
      },
    },
  },
};
</script>

<style lang="stylus" scoped>
  .info-container
    width 100%
    height 100%
    color $grayFontColor
    padding 20px 25px 25px
    font-size 16px
    text-align left
    word-break break-all
    p.contact-us
      margin-top 20px
</style>

<i18n>
{
	"en": {
		"Coming soon": "Coming soon"
	},
	"zh": {
		"Coming soon": ""
	}
}
</i18n>
